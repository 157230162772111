.ellipsis-text {
  overflow-y: hidden;
  text-overflow: ellipsis;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-tc-sb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}
